import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

const Ketentuan = () => {
  return (
    <>
    <Helmet>
        <title>Syarat dan Ketentuan - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Informasi seputar syarat dan ketentuan Ethical Hacker Indonesia" />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/ketentuan/" />
        <meta property="og:title" content="Syarat dan Ketentuan" />
        <meta property="og:description" content="Informasi seputar syarat dan ketentuan Ethical Hacker Indonesia" />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Syarat dan Ketentuan"  />
        <meta name="twitter:description" content="Informasi seputar syarat dan ketentuan Ethical Hacker Indonesia" />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Syarat dan Ketentuan
                  </h2>
                  <p className="gr-text-8 mb-13">
                    Informasi seputar syarat dan ketentuan Ethical Hacker Indonesia
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9">
                    Website ini dioperasikan oleh Ethical Hacker Indonesia. 
                    Ketika menggunakan situs ini, baca syarat dan ketentuan penggunaan di bawah ini secara 
                    menyeluruh dan menyetujuinya sebelum menggunakan situs ini. Ketahuilah bahwa dengan 
                    menggunakan situs ini, Anda menyetujui semua syarat dan ketentuan penggunaan di bawah ini. 
                    Syarat dan ketentuan ini bisa berubah tanpa ada pemberitahuan terlebih dahulu, jadi kami 
                    meminta Anda untuk mengkonfirmasi syarat dan ketentuan terbaru sebelum menggunakan situs ini.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Hak Kekayaan Intelektual</h3>
                    <p className="gr-text-9">
                      Isi pada situs ini dilindungi oleh undang-undang hak cipta yang berlaku di masing-masing negara 
                      dan perjanjian-perjanjian terkait. Semua konten dalam situs web ini menggunakan lisensi publik 
                      Creative Commons, <a href="https://creativecommons.org/licenses/by-nc/4.0/">CC-BY-NC 4.0</a>. Anda bebas untuk
                      menyalin dan mendistribusikan ulang materi yang ada dalam medium atau format apapun. Juga diperkenankan untuk menggubah, mengubah, dan membuat turunan dari materi yang ada.
                      Anda harus mencantumkan kredit yang sesuai, mencantumkan tautan terhadap lisensi, dan menyatakan apabila ada perubahan yang dilakukan atas materi yang Anda ambil.
                      Yang perlu menjadi perhatian, <b>Anda tidak dapat menggunakan materi ini untuk kepentingan komersial</b>.
                    </p>
                  </div>
                  <h3 className="gr-text-8 font-weight-bold pb-3">Laporan Penipuan</h3>
                    <p className="gr-text-9">
                      Pada halaman <a href="https://hack.co.id/laporkan">Laporkan Penipuan</a>, kami menyediakan form aduan jika kamu menjadi korban penipuan. Setiap data laporan yang masuk akan kami validasi terlebih dahulu. Laporan
                      yang masuk namun tidak lolos saat proses validasi yang kami lakukan, maka laporan itu tidak akan diproses. Selanjutnya jika sebuah laporan kami anggap dari sumber yang kredibel, kami
                      akan memproses laporan tersebut dan mencarikan informasi tentang seseorang yang terduga penipu. Setelah informasi tentang terduga penipu kami dapatkan, kami akan mengirimkan data
                      terduga penipu tersebut ke email yang digunakan ketika membuat laporan. Apa saja faktor yang mempengaruhi laporan kamu tidak kami proses?
                    </p>
                    <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Mencantumkan akun media sosial yang terduga adalah akun palsu
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Tidak melaporkan penipuan menggunakan email dan nomor hp utama
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Informasi transaksi dan kronologi yang kami anggap tidak jelas
                      </li>
                    </ul>
                  <div className="single-block mb-13">
                    <h3 className="gr-text-8 font-weight-bold">Link</h3>
                      <p className="gr-text-9">
                      Link ke situs ini, pada prinsipnya, tidak terbatas pada keadaan bahwa “Instruksi untuk Link” dipatuhi. 
                      Ketahuilah bahwa Ethical Hacker Indonesia bisa meminta agar suatu link dihapus, tergantung pada isi situs dan metode penggunaan untuk link.
                      Instruksi untuk Link :
                    </p>
                    <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Ketika membuat link ke situs Ethical Hacker Indonesia, kami meminta agar Anda menggunakan nama Ethical Hacker Indonesia.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Kami meminta agar Anda membuat link ke Halaman Utama dari situs Ethical Hacker Indonesia.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Kami meminta agar Anda tidak menampilkan situs Ethical Hacker Indonesia dalam satu frame.
                      </li>
                    </ul>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Penolakan
                    </h3>
                    <p className="gr-text-9 pb-5">
                    Ethical Hacker Indonesia melakukan semua perhatian yang wajar berkenaan dengan pembaharuan informasi dan pengoperasian situs ini. 
                    Namun, Ethical Hacker Indonesia tidak memberikan perwakilan dan jaminan apapun, baik secara tersurat maupun tersirat, termasuk 
                    jaminan tanpa batasan yang tersirat yang berhubungan dengan akurasi, kegunaan, kecocokan, dan / atau kesesuaian 
                    untuk tujuan tertentu, dari informasi yang ada pada situs. Ethical Hacker Indonesia juga tidak memberikan jaminan terhadap terjadinya 
                    kesalahan, keberadaan virus komputer pada server dimana situs tersebut di-host atau terhadap program-program berbahaya 
                    lainnya. Ethical Hacker Indonesia tidak akan bertanggung jawab, dengan cara apapun, atas adanya kerugian atau kerusakan yang timbul sebagai 
                    akibat dari informasi pada situs ini atau sebagai akibat dari penggunaan situs ini. Informasi pada website ini bisa berubah 
                    tanpa ada pemberitahuan sebelumnya dan Ethical Hacker Indonesia berhak untuk menangguhkan atau melanjutkan pengoperasian website ini.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Hukum dan Peradilan yang Tepat
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                    Syarat dan Ketentuan Penggunaan ini akan diatur oleh dan ditafsirkan sesuai dengan undang-undang Indonesia yang berlaku. 
                    Setiap dan semua perselisihan yang timbul dari atau yang berhubungan dengan situs ini harus dibawa ke Pengadilan di Indonesia.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Ketentuan;
